import { ICertificatePropsResult } from "features/certificateDownload/api/queries.app";
import { cloneDeep } from "lodash";
import type { StoreType } from "polotno/model/store";
import QRCode from "qrcode-svg";

import { urlToBase64 } from "utils/urlToBase64";

import { checkForRichText } from "../../components/PolotnoWorkspace/polotnoWorkspace.helpers";

import { parseFontsFromVirtualbadge } from "./loadVBfonts";

/**
 * Function to escape special characters in a string
 * @param {string} string
 * @returns {string} escaped string
 * @example
 * escapeRegExp("Hello. World!"); // "Hello\. World\!"
 */
export const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

/**
 * Function to create RegExp for searching text
 * @param {string} searchingText
 * @returns {RegExp} RegExp for searching text
 * @example
 * createRegExpForText("Hello. World!"); // /{{Hello\. World\!}}/g
 */
export const createRegExpForText = (searchingText: string): RegExp => {
    const escapedText = escapeRegExp(searchingText);
    // If the text is a number, we want to match only whole words.
    if (/^\d+$/.test(searchingText)) {
        return new RegExp(`{{\\b${escapedText}\\b}}`, "g");
    }
    return new RegExp(`{{${escapedText}}}`, "g");
};

/**
 * Function to create base64 encoded QR code
 * @param {string} link
 * @param {number} width
 * @param {number} height
 *
 * @returns {string} base64 encoded QR code as svg
 */
export const createQRCode = (link: string, width = 102, height = 102): string => {
    const qrCode = new QRCode({
        content: link,
        padding: 0,
        width,
        height,
        color: "#000000",
        background: "#ffffff",
        ecl: "M",
    });

    function b64EncodeUnicode(str: string): string {
        return btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(Number(`0x${p1}`)))
        );
    }

    const svgString = qrCode.svg();
    const base64 = b64EncodeUnicode(svgString);

    return `data:image/svg+xml;base64,${base64}`;
};

/**
 * Generates a random id for Polotno elements.
 * @returns {string} a random string of 10 characters.
 * @example
 * generateId(); // "aBcDeFgHiJ"
 */
export const generateId = (): string => {
    const possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let id = "";
    for (let i = 0; i < 10; i++) {
        id += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }
    return id;
};

/**
 * Export a thumbnail with a given width and heigth.
 * Call this with await in an async function
 * @param dataURL
 * @param width
 * @param height
 * @returns
 */
export const exportThumbnailWithPolotno = async (dataURL: string, width: number, height: number): Promise<string> => {
    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        const badgeImg = new Image();

        badgeImg.onload = () => {
            const badgeWidth = badgeImg.width * (canvas.height / badgeImg.height);
            ctx.drawImage(badgeImg, canvas.width / 2 - badgeWidth / 2, 0, badgeWidth, canvas.height);
            setTimeout(() => {
                resolve(canvas.toDataURL());
            }, 200);
        };
        badgeImg.src = dataURL;
    });
};

/**
 * Converts an ArrayBuffer to a Base64-encoded string.
 * @param {ArrayBuffer} buffer - The ArrayBuffer to be converted.
 * @returns {string} The Base64-encoded string representation of the input ArrayBuffer.
 */
export function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

/**
 * Converts a Base64-encoded string to an ArrayBuffer.
 * @param {string} base64 - The Base64-encoded string to be converted.
 *@returns {ArrayBuffer} The ArrayBuffer representation of the input Base64-encoded string.
 */
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

/**
 * Get the profile picture element from the Polotno object.
 * @param polotnoProps
 * @returns
 */
export const getCustomElement = (
    polotnoProps: PolotnoDesigner.PolotnoBadgePropsData,
    customType: PolotnoDesigner.CustomFieldType
): PolotnoDesigner.PolotnoObject | undefined => {
    let polotnoElement;
    polotnoProps.pages.forEach((page) => {
        page.children.forEach((child) => {
            if (child.custom && child.custom.type === customType) {
                polotnoElement = child;
            }
        });
    });
    return polotnoElement;
};

/**
 * Update the profile picture element in the Polotno object.
 * @param polotnoProps
 * @param src
 * @returns
 */
export const updateProfilePictures = async (polotnoProps: PolotnoDesigner.PolotnoBadgePropsData, src: string) => {
    // In case the src is a blob url, we have to convert it to base64 before we proceed, otherwise the polotno cloud rendering will not work with the local blob (example: blob:https://localhost:5000/fe059680-b703-4339-b140-8b2f8cff9475)
    // eslint-disable-next-line no-param-reassign
    if (src.startsWith("blob:")) src = await urlToBase64(src); // Replace blob with ht
    // eslint-disable-next-line no-param-reassign
    polotnoProps.pages = polotnoProps.pages.map((page) => ({
        ...page,
        children: page.children.map((el) => {
            if (el.custom?.type === "recipient_image") {
                if (el.type === "svg") {
                    return {
                        id: el.id,
                        type: "image",
                        src,
                        width: el.width,
                        height: el.height,
                        x: el.x,
                        y: el.y,
                        blurEnabled: el.blurEnabled,
                        blurRadius: el.blurRadius,
                        brightnessEnabled: el.brightnessEnabled,
                        brightness: el.brightness,
                        shadowEnabled: el.shadowEnabled,
                        shadowColor: el.shadowColor,
                        shadowBlur: el.shadowBlur,
                        shadowOpacity: el.shadowOpacity,
                        cornerRadius: el.width ? el.width / 2 : 0,
                        visible: true,
                        custom: {
                            type: "recipient_image",
                        },
                    };
                }
                return {
                    ...el,
                    src,
                    cornerRadius: el.width ? el.width / 2 : 0,
                };
            }
            return el;
        }),
    }));
};

/**
 * This function is used to determine the properties of the Polotno object based on the payload received.
 * The payload contains information about a certificate's recipient, like the LinkedIn or Facebook profile.
 * It modifies the profile picture and the recipient's name on the certificate accordingly.
 *
 * @param {ICertificatePropsResult} payload - The payload object which contains profile data.
 * @param {PolotnoDesigner.PolotnoBadgePropsData} polotnoPropsData - The initial Polotno object properties.
 *
 * @returns {PolotnoDesigner.PolotnoBadgePropsData} - The updated Polotno object properties.
 *
 * @remarks
 * This function will update the following properties if the recipient has a visible profile picture:
 * - recipient's name
 * - profile picture
 *
 * It uses the highest resolution image available for the profile picture.
 */
export const setProfilePictureInPolotnoProps = (
    payload: ICertificatePropsResult,
    polotnoPropsData: PolotnoDesigner.PolotnoBadgePropsData
) => {
    const polotnoProps = cloneDeep(polotnoPropsData);

    const profilePicElement = getCustomElement(polotnoProps, "recipient_image");

    if (!profilePicElement || !profilePicElement?.visible) {
        return polotnoProps;
    }

    if (payload?.linkedin) {
        // Get linkedin profile information and add them to the badge props.
        const profile = payload.linkedin;

        let linkedinPic;
        if (profile.profilePicture) {
            const linkedinPicElements = profile.profilePicture["displayImage~"].elements;
            // Get image with highest resolution
            linkedinPic = linkedinPicElements[linkedinPicElements.length - 1].identifiers[0].identifier;
            // Update linkedin picture ->
            updateProfilePictures(polotnoProps, linkedinPic);
        }
    } else if (payload.facebook) {
        // Get profile information and add them to the badge props
        const profile = payload.facebook;
        const facebookPic = profile.picture;

        // Load image
        updateProfilePictures(polotnoProps, facebookPic);
    }

    return polotnoProps;
};

export interface ISecondPageMeasurements {
    x: number;
    idContentY: number;
    validationContentY: number;
    fontSize: number;
}
/**
 * Get measures for pdf-lib to draw id number and validation url
 * @param {StoreType} store
 * @returns {ISecondPageMeasurements} measures for pdf-lib
 */
export const getIdNumberAndValidationUrlMeasurements = (store: StoreType, isValidationPageEnabled: boolean) => {
    const lastPolotnoPage = store.pages[store.pages.length - 1];
    const getElementByName = (name: string) => lastPolotnoPage.children.find((item) => item.name === name);

    const issueDateTitle = getElementByName("issueDateTitle");
    const issueDateContent = getElementByName("issueDateContent");
    const idTitle = getElementByName("idTitle");
    const validationTitle = getElementByName("validationTitle");

    const distanceBetweenTitleAndContent = issueDateContent.y - issueDateTitle.y;

    // We have to add 50% more margin to the distance between title and content
    // Because of difference between pdf-lib and polotno
    const extraMargin = distanceBetweenTitleAndContent * 0.5;

    const idContentY: number = isValidationPageEnabled ? idTitle.y + distanceBetweenTitleAndContent + extraMargin : 0;
    const validationContentY: number = isValidationPageEnabled
        ? validationTitle.y + distanceBetweenTitleAndContent + extraMargin
        : 0;
    const fontSize: number = issueDateTitle.fontSize;
    const x: number = issueDateTitle.x;

    return { x, idContentY, validationContentY, fontSize };
};

type BadgeDimensions = { width: number; height: number };

// Legacy formats that came from the old designer. They are used only for the old designs which were not be changed.
// Exception: BADGE_1_TO_1 is used for the new designs as well. It's the format for the badges.
// LEGACY_BADGE_3_TO_4 is used for some old events in the local environment.
export const REDUCED_FORMATS: { [key: string]: BadgeDimensions } = {
    A4_LANDSCAPE: { width: 504.87, height: 357 },
    A4_PORTRAIT: { width: 357, height: 504.87 },
    US_LETTER_LANDSCAPE: { width: 462, height: 357 },
    US_LETTER_PORTRAIT: { width: 357, height: 462 },
    LEGACY_BADGE_3_TO_4: { width: 357, height: 476 },
};
// Full formats for the certificates. They are used for the full size of the designs. All old certificates will be converted to these formats in the beginning of the design process and then they will be saved in the new format. DPI = 72
export const DEFAULT_FORMATS: { [key: string]: BadgeDimensions } = {
    BADGE_1_TO_1: { width: 357, height: 357 },
    A4_LANDSCAPE: { width: 842.4, height: 597.6 },
    A4_PORTRAIT: { width: 597.6, height: 842.4 },
    US_LETTER_LANDSCAPE: { width: 792, height: 612 },
    US_LETTER_PORTRAIT: { width: 612, height: 792 },
    LEGACY_BADGE_3_TO_4: { width: 597.6, height: 796.8 },
};

/**
 * Get badge dimensions for a given format (default or reduced)
 *
 * @param {VbDesigner.BadgeFormat} formatName - one of the available formats
 * @param sizeType - "default" or "reduced" (Optional)
 * @returns {BadgeDimensions} the dimensions of the badge
 */
export const getBadgeFormatSize = (
    formatName: VbDesigner.BadgeFormat,
    sizeType: "default" | "reduced" = "default"
): BadgeDimensions => {
    const format = DEFAULT_FORMATS[formatName];
    if (!format) {
        return DEFAULT_FORMATS.A4_PORTRAIT;
    }

    if (sizeType === "reduced") {
        return REDUCED_FORMATS[formatName] || format;
    }
    return format;
};

/**
 * Check if the current badge format is 'full' or 'reduced'
 * @returns {"default" | "reduced"} type of the badge format
 * @throws {Error} if no format type is found for the current badge
 */
export const checkBadgeFormat = (polotnoProps: PolotnoDesigner.PolotnoBadgePropsData): "default" | "reduced" => {
    // Assuming there is a way to get current badge width and height
    const currentWidth = polotnoProps.width;
    const currentHeight = polotnoProps.height;

    // Check if the dimensions match any entry in the DEFAULT_FORMATS
    const isFull = Object.values(DEFAULT_FORMATS).some(
        (format) => format.width === currentWidth && format.height === currentHeight
    );

    if (isFull) {
        return "default";
    }

    // Check if the dimensions match any entry in the REDUCED_FORMATS
    const isReduced = Object.values(REDUCED_FORMATS).some(
        (format) => format.width === currentWidth && format.height === currentHeight
    );

    if (isReduced) {
        return "reduced";
    }

    throw new Error("No format type found for the current badge dimensions");
};

/**
 * Generate a image/pdf with Polotno cloud rendering.
 * This function will generate a badge with the given Polotno object and format.
 * It will return the generated file as a base64 string.
 * The function will wait for the Polotno cloud rendering to finish before returning the result.
 * If the rendering takes more than 30 seconds, the function will stop and return undefined.
 *
 * TODO: Implement the generation logic ourselves instead of using the Polotno cloud rendering.
 * https://futurenextbusiness.visualstudio.com/Virtual%20Badges/_workitems/edit/12795/
 *
 * @param store
 * @param polotnoJson
 * @param format
 * @returns
 */
export const generateWithPolotnoCloudRendering = async (
    polotnoJson: PolotnoDesigner.PolotnoBadgePropsData,
    format: "png" | "pdf" = "png"
) => {
    // I have to clone the polotnoJson object, otherwise the original object will be modified and cause some weird side effects
    const polotnoJsonClone = cloneDeep(polotnoJson);

    // Custom fonts and VB fonts have different structures, so we need to make them the same before sending them to the Polotno cloud rendering.
    const fonts = (await parseFontsFromVirtualbadge(0, polotnoJsonClone?.fonts)) as PolotnoDesigner.CustomFont[];

    polotnoJsonClone.fonts = fonts;
    const req = await fetch(`https://api.polotno.com/api/renders?KEY=${process.env.POLOTNO_API_KEY}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            design: polotnoJsonClone,
            pixelRatio: 8,
            format,
            htmlTextRenderEnabled: checkForRichText(polotnoJsonClone),
            textVerticalResizeEnabled: true,
            textSplitAllowed: true,
        }),
    });
    const job = await req.json();

    let backendGeneratedFile;
    let count = 0;

    // Add an initial delay before starting the loop.
    // Polotno cloud rendering takes some time to have files rendered.
    // We don't need to make the first calls immediately after the request.
    await new Promise((resolve) => {
        setTimeout(resolve, 5000);
    });

    while (!backendGeneratedFile && count < 30) {
        // eslint-disable-next-line no-await-in-loop
        const res = await fetch(`https://api.polotno.com/api/renders/${job.id}?KEY=${process.env.POLOTNO_API_KEY}`);
        // eslint-disable-next-line no-await-in-loop
        const data = await res.json();

        if (data.status === "done") {
            backendGeneratedFile = data.output;
            break;
        }

        // Wait for 1s before making the next call
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
            setTimeout(resolve, 1000);
        });

        count++;
    }

    return backendGeneratedFile;
};

/**
 * Regular expression for detecting rich text HTML-like tags.
 * Matches opening and closing tags, including those at the end of a string.
 */
export const RICH_TEXT_REGEX = /<\/?[^>]+(>|$)/g;

/**
 * Removes all rich text HTML-like tags from a given string.
 *
 * @param text - The input string potentially containing rich text tags.
 * @returns A clean string with all rich text tags removed.
 */
export const cleanRichText = (text: string): string => text.replace(RICH_TEXT_REGEX, "");
