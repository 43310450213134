import { To } from "react-router-dom";
import i18next from "i18next";

import { TNotificationMessage } from "../redux/slices/types/notification.types";
import { TRedirectState } from "../redux/slices/types/redirection.types";

import { routes } from "./routes";

export type TReasonKeys = "no_data" | "insufficient_funds" | "slug_not_found" | "too_many_requests" | "no_reason";

export type TReasonAction = {
    reason: TReasonKeys;
    route?: To;
    noNotification?: boolean;
};

export type TReason = {
    redirect: TRedirectState;
    notification: TNotificationMessage | null;
};

export const reasons = (route = ""): Record<TReasonKeys, TReason> => ({
    // No reason to redirect
    no_reason: {
        redirect: {
            to: route,
            options: {
                replace: false,
            },
        },
        notification: null,
    },
    // token invalid, no data to render, revoked certificate (403 forbidden)
    no_data: {
        redirect: {
            to: route,
            options: {
                replace: true,
            },
        },
        notification: {
            message: i18next.t("message.error.token_invalid") as string,
            variant: "error",
            options: {
                autoHideDuration: 5000,
            },
        },
    },
    // insufficient funds
    insufficient_funds: {
        redirect: {
            to: route,
            options: {
                replace: true,
            },
        },
        notification: {
            message: i18next.t("message.error.insufficient_funds") as string,
            variant: "error",
            options: {
                autoHideDuration: 5000,
            },
        },
    },
    // wrong orga or certificate slug, Event is not active.
    slug_not_found: {
        redirect: {
            to: routes.LandingPage,
            options: {
                replace: true,
            },
        },
        notification: {
            message: i18next.t("message.error.wrong_slugs") as string,
            variant: "error",
            options: {
                autoHideDuration: 600000,
            },
        },
    },
    too_many_requests: {
        redirect: {
            to: route,
            options: {
                replace: false,
            },
        },
        notification: {
            message: i18next.t("message.error.too_many_requests") as string,
            variant: "error",
            options: {
                autoHideDuration: 5000,
            },
        },
    },
});
