import type { FC } from "react";
import CertificateValidatorPreview from "features/certificateValidator/components/CertificateValidatorPreview";

import useTranslation from "i18n/hooks/useTranslation";
import * as commonStyles from "utils/commonStyles";
import { getUserLocaleDate } from "utils/dates.helpers";
import { findRecipientImageInPolotnoProps, getTranslatedCertificateOrBadge } from "utils/misc";
import { theme } from "utils/Theme/theme";
import useDeviceBreakpoint from "utils/useDeviceBreakpoint/useDeviceBreakpoint";

import { Box, Stack, stackClasses, styled } from "@mui/material";

import Divider from "components/shared/Divider";
import Typography from "components/shared/Typography";

import { useGetCurrentSelectedCertificate } from "../../../../redux/hooks/badge";
import { useGetCertificateDetails } from "../../../../redux/hooks/queryHooks";
import eventSelectorService from "../../../../redux/selector/events";
import { useAppSelector } from "../../../../redux/store";
import { EVariantTranslation } from "../../../../types/enums";
import backendPdfDownloadHelper from "../../../../utils/backendPdfDownloadHelper";
import BackendPdfCertificateInCreation from "../BackendPdfCertificateInCreation/BackendPdfCertificateInCreation";
import MultiCertificatePreviewWrapper from "../CertificateDownloadContainer/MultiCertificatePreviewWrapper";
import DownloadSection from "../DownloadSection";
import ShareCertificate from "../ShareCertificate";
import WalletSection from "../WalletSection/WalletSection";

import * as styles from "./CertificateDetails.styles";

const CertificateDetailsContainer = styled(Stack)(() => ({
    [`&.${stackClasses.root}>:not(style)~:not(style)`]: {
        padding: "0px 10px",
        marginTop: "24px",
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
        },
    },
}));

const CertificateDetails: FC = () => {
    const t = useTranslation();
    const eventDetails = useAppSelector(eventSelectorService.selectFetchedEvent);
    const certificate = useGetCurrentSelectedCertificate();

    const { isTablet } = useDeviceBreakpoint();

    const { data: certificateDetails, isSuccess: detailsFetched } = useGetCertificateDetails();

    const checkSingleRecipient = backendPdfDownloadHelper.checkSingleRecipient(
        eventDetails.event_pdf_type,
        certificate
    );

    const isBackendPdfStillProcessing =
        checkSingleRecipient.isBackendPdf &&
        !checkSingleRecipient.isGenerated &&
        !findRecipientImageInPolotnoProps(certificate.badge_props as PolotnoDesigner.PolotnoBadgePropsData);

    return (
        <CertificateDetailsContainer spacing={3}>
            <Typography variant="h3" gutterBottom={false} mt={{ md: 0, xs: 5 }} px="10px">
                {isBackendPdfStillProcessing
                    ? t("certificate_download.certificate_details.backend_pdf_in_creation.info", {
                          certificateType: getTranslatedCertificateOrBadge(
                              eventDetails?.event_type,
                              EVariantTranslation.LOWERCASE_SINGULAR
                          ),
                      } as any)
                    : t("certificate_download.certificate_details.heading", {
                          certificateType: getTranslatedCertificateOrBadge(
                              eventDetails?.event_type,
                              EVariantTranslation.LOWERCASE_SINGULAR
                          ),
                      } as any)}
            </Typography>
            <Divider />
            {!isTablet && (
                <>
                    <MultiCertificatePreviewWrapper>
                        <CertificateValidatorPreview />
                    </MultiCertificatePreviewWrapper>
                    <Divider />
                </>
            )}
            {detailsFetched && (
                <div>
                    <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                        <Typography variant="body1" color="text.secondary" sx={styles.infosText}>
                            {t("certificate_download.certificate_details.certificate_name", {
                                certificateType: getTranslatedCertificateOrBadge(
                                    eventDetails?.event_type,
                                    EVariantTranslation.UPPERCASE_SINGULAR
                                ),
                            } as any)}
                        </Typography>
                        <Typography variant="body1">{certificateDetails.event__name}</Typography>
                    </Box>
                    <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                        <Typography variant="body1" color="text.secondary" sx={styles.infosText}>
                            {t("certificate_download.certificate_details.issued_by")}
                        </Typography>
                        <Typography variant="body1">{certificateDetails.event__certificate_issuer}</Typography>
                    </Box>
                    {certificate.validation_page_enabled && (
                        <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                            <Typography variant="body1" color="text.secondary" sx={styles.infosText}>
                                {t("certificate_download.certificate_details.certificate_id", {
                                    certificateType: getTranslatedCertificateOrBadge(
                                        eventDetails?.event_type,
                                        EVariantTranslation.UPPERCASE_SINGULAR
                                    ),
                                } as any)}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    // We need a width of 350px to be able to display the certificate id correctly and not force a jump of the viewpoint
                                    width: 350,
                                }}
                            >
                                {certificate.certificate_id}
                            </Typography>
                        </Box>
                    )}
                    <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                        <Typography variant="body1" color="text.secondary" sx={styles.infosText}>
                            {t("certificate_download.certificate_details.issue_date")}
                        </Typography>
                        <Typography variant="body1">
                            {getUserLocaleDate(certificate.issue_date || certificateDetails.issue_date)}
                        </Typography>
                    </Box>
                    {(certificate.expiration_date || certificateDetails.expiration_date) && (
                        <Box sx={{ ...commonStyles.flex, ...styles.infoRow }}>
                            <Typography variant="body1" color="text.secondary" sx={styles.infosText}>
                                {t("certificate_download.certificate_details.expiration_date")}
                            </Typography>
                            <Typography variant="body1">
                                {getUserLocaleDate(certificate.expiration_date || certificateDetails.expiration_date)}
                            </Typography>
                        </Box>
                    )}
                </div>
            )}
            <Divider />
            {isBackendPdfStillProcessing ? <BackendPdfCertificateInCreation /> : <ShareCertificate />}
            {eventDetails.event_wallet_enabled && certificate && certificateDetails && (
                <>
                    <Typography variant="h5">
                        {t("certificate_download.certificate_details.add_to_wallet", {
                            certificateType: getTranslatedCertificateOrBadge(
                                eventDetails?.event_type,
                                EVariantTranslation.LOWERCASE_SINGULAR
                            ),
                        } as any)}
                    </Typography>
                    <WalletSection certificate={certificate} certificateDetails={certificateDetails!} />
                </>
            )}
            <DownloadSection />
        </CertificateDetailsContainer>
    );
};

export default CertificateDetails;
