import { FC, useEffect } from "react";
import { parseFontsFromVirtualbadge } from "features/certificateDownload/utils/Polotno/loadVBfonts";
import Workspace from "polotno/canvas/workspace";
import {
    unstable_setTextOverflow,
    unstable_setTextSplitAllowed,
    unstable_setTextVerticalResizeEnabled,
    unstable_useHtmlTextRender,
} from "polotno/config";
import { createStore } from "polotno/model/store";
import { addGlobalFont, setGoogleFonts } from "polotno/utils/fonts";

import { checkForRichText } from "./polotnoWorkspace.helpers";

// Polotno store initialization
export const store = createStore({
    key: process.env.POLOTNO_API_KEY!,
    showCredit: false,
});

// Remove Google fonts.
setGoogleFonts([]);

// Add VB fonts.
parseFontsFromVirtualbadge().then((fonts) =>
    fonts.forEach(({ fontFamily, styles }) => {
        addGlobalFont({
            fontFamily,
            styles,
        });
    })
);

/**
 * Add vertical text resize support. This allows the user to resize text height and vertical alignment.
 * @see {@link https://polotno.com/docs/vertical-text-resize/}
 */
unstable_setTextVerticalResizeEnabled(true);

/**
 * Default text overflow for the entire project.
 * @see {@link https://polotno.com/docs/text-overflow}
 */
unstable_setTextOverflow("change-font-size");

/**
 * Allow text to be split into multiple lines when it is too long to fit in the bounding box.
 */
unstable_setTextSplitAllowed(true);

/*
We have to mount Workspace for making downloading certs work with Polotno.
We also have to set the width and height at least to 1px, otherwise Polotno will show a warning.
Now it is invisible and does not take any space.
*/
const workspaceStyles = {
    opacity: 0,
    position: "absolute",
    width: "1px",
    height: "1px",
} as React.CSSProperties;

interface PolotnoWorkspaceProps {
    isRichTextEnabled?: boolean;
}

const PolotnoWorkspace: FC<PolotnoWorkspaceProps> = ({ isRichTextEnabled }): JSX.Element => {
    // Define the rich text mode status.
    useEffect(() => {
        unstable_useHtmlTextRender(isRichTextEnabled ?? checkForRichText(store));
    }, [isRichTextEnabled]);

    return (
        <div style={workspaceStyles}>
            <Workspace store={store} />
        </div>
    );
};

export default PolotnoWorkspace;
